<template>
  <div>
    <section v-if="actualForm.collection_method === 1">
      <b-card no-body>
        <template #header>
          <h4 class="mr-auto">
            Invoice: {{ invoiceData.invoice_no }}
          </h4>
          <span>
            <b-badge
              :class="[
                { 'badge badge-warning': invoiceData.invoice_status_id === 1 },
                { 'badge badge-success': invoiceData.invoice_status_id === 2 },
                { 'badge badge-danger': invoiceData.invoice_status_id === 3 },
                { 'badge badge-danger': invoiceData.invoice_status_id === 4 }
              ]"
            >

              <template v-if="invoiceData.invoice_status_id === 1">PENDING</template>
              <template v-if="invoiceData.invoice_status_id === 2">PAID</template>
              <template v-if="invoiceData.invoice_status_id === 3">FAILED</template>
              <template v-if="invoiceData.invoice_status_id === 4">INVALID</template>
            </b-badge>

          </span>
        </template>

        <div class="px-2">
          <b-row>
            <b-col cols="6">
              <h5>Customer Info</h5>
              {{ invoiceData.full_name }}<br>
              {{ invoiceData.email }}<br>
              {{ invoiceData.phone_number }}<br>
            </b-col>
          </b-row>
        </div>

        <section class="mt-3">

          <h4 class="px-2 mb-1">
            Invoice Details
          </h4>
          <b-table-simple
            responsive
          >
            <b-thead>
              <b-tr>
                <b-th>
                  Product Name
                </b-th>
                <b-th
                  class="text-right"
                >
                  Quantity
                </b-th>
                <b-th
                  class="text-right"
                >
                  Item Price ({{ myCurrency }})
                </b-th>
                <b-th
                  class="text-right"
                >
                  Total Price ({{ myCurrency }})
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template>
                <b-tr>
                  <b-td>
                    {{ actualForm.title }}</b-td>
                  <b-td
                    class="text-right"
                  >
                    <template>
                      {{ invoiceData.quantity }}
                    </template>
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    <template>
                      <vue-numeric
                        v-model="actualForm.min_amount"
                        read-only
                        class="form-control form-control-sm text-right"
                        :precision="2"
                      />
                    </template>
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    <vue-numeric
                      :value="actualForm.min_amount * invoiceData.quantity"
                      read-only
                      class="form-control form-control-sm text-right"
                      :precision="2"
                    />
                  </b-td>
                </b-tr>
              </template>
              <b-tr>
                <b-th
                  colspan="3"
                  class="text-right"
                >
                  Amount
                </b-th>
                <b-th
                  colspan="1"
                  class="text-right"
                >
                  <vue-numeric
                    :value="actualForm.min_amount * invoiceData.quantity"
                    read-only
                    class="form-control form-control-sm text-right"
                    :precision="2"
                  />
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </section>

      </b-card>

    </section>
    <section v-if="actualForm.collection_method === 2">
      <b-card no-body>
        <template #header>
          <h4 class="mr-auto">
            Invoice: {{ invoiceData.invoice_no }}
          </h4>
          <span>
            <b-badge
              :class="[
                { 'badge badge-warning': invoiceData.invoice_status_id === 1 },
                { 'badge badge-success': invoiceData.invoice_status_id === 2 },
                { 'badge badge-danger': invoiceData.invoice_status_id === 3 },
                { 'badge badge-danger': invoiceData.invoice_status_id === 4 }
              ]"
            >

              <template v-if="invoiceData.invoice_status_id === 1">PENDING</template>
              <template v-if="invoiceData.invoice_status_id === 2">PAID</template>
              <template v-if="invoiceData.invoice_status_id === 3">FAILED</template>
              <template v-if="invoiceData.invoice_status_id === 4">INVALID</template>
            </b-badge>

          </span>
        </template>

        <div class="px-2">
          <b-row>
            <b-col cols="6">
              <h5>Customer Info</h5>
              {{ invoiceData.full_name }}<br>
              {{ invoiceData.email }}<br>
              {{ invoiceData.phone_number }}<br>
            </b-col>
          </b-row>
        </div>

        <section class="mt-3">

          <h4 class="px-2 mb-1">
            Invoice Details
          </h4>
          <b-table-simple
            responsive
          >
            <b-thead>
              <b-tr>
                <b-th>
                  Product Name
                </b-th>
                <b-th
                  class="text-right"
                >
                  Quantity
                </b-th>
                <b-th
                  class="text-right"
                >
                  Item Price ({{ myCurrency }})
                </b-th>
                <b-th
                  class="text-right"
                >
                  Total Price ({{ myCurrency }})
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(product,index) in selectedProducts">
                <b-tr :key="`collectionProduct${index}`">
                  <b-td>
                    {{ product.name }}</b-td>
                  <b-td
                    class="text-right"
                  >
                    <template>
                      {{ product.quantity }}
                    </template>
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    <template>
                      <vue-numeric
                        v-model="product.amount"
                        read-only
                        class="form-control form-control-sm text-right"
                        :precision="2"
                      />
                    </template>
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    <vue-numeric
                      :value="calculatedRowPrice(index, product.quantity,product.amount)"
                      read-only
                      class="form-control form-control-sm text-right"
                      :precision="2"
                    />
                  </b-td>
                </b-tr>
              </template>
              <b-tr>
                <b-th
                  colspan="3"
                  class="text-right"
                >
                  Amount
                </b-th>
                <b-th
                  colspan="1"
                  class="text-right"
                >
                  <vue-numeric
                    :value="calculatedTotal"
                    read-only
                    class="form-control form-control-sm text-right"
                    :precision="2"
                  />
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </section>

      </b-card>
    </section>

    <company-collection-payment-records
      v-if="invoiceData.invoice_no"
      :bill-invoice="invoiceData.invoice_no"
      :account-id="invoiceData.account_id"
    />
  </div>
</template>

<script>
import humaniseMixin from '@/common/humanise.mixin'
import CompanyCollectionPaymentRecords from '@/views/administration/accounts/details/collections/component/companyCollectionPaymentRecords.vue'

export default {
  components: {
    CompanyCollectionPaymentRecords,
    /* billPaymentRecords, */
  },
  mixins: [humaniseMixin],
  props: {

  },
  data() {
    return {
      fieldSize: 'sm',
      account_id: null,
      actualForm: {
      },
      invoiceData: {
      },

      reconstructProductListingResult: null,
      selected: [],
      selectedProducts: [],

      sortDesc: true,
      sortBy: 'created_at',
    }
  },
  computed: {
    sortObj() {
      const obj = {
        parameter_name: this.sortBy,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    invoiceNo() {
      const invoiceNo = this.invoiceData.invoice_no
      if (invoiceNo) {
        return invoiceNo
      }
      return ''
    },
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    productsListOpts() {
      const form = this.$store.getters['merchant/getMerchantProductsOpts']

      if (form) {
        return form
      }
      return {}
    },
    calculatedTotalPricePaymentForm(quantity, price) {
      if (quantity * price) {
        return quantity * price
      }
      return 0
    },
    calculatedTotal() {
      const finalProdSelected = this.selectedProducts
      const x = finalProdSelected.reduce((a, b) => a + (b.total_amount || 0), 0)
      if (x) {
        return x
      } return 0
    },
    calculatedTotalPayment() {
      const finalProdSelected = this.actualForm.min_amount
      return finalProdSelected
    },
  },
  async mounted() {
    this.account_id = this.$store.getters['accounts/getAccountId']
    await this.$store.dispatch('systemConstants/getSystemConstants')

    await this.$store.dispatch('collections/getCollectionById', this.$route.params.collectionId)
      .then(({ data }) => {
        this.actualForm = data.data
      })

    await this.$store.dispatch('customerBills/getCustomerBillsById', { collection_id: this.$route.params.invoiceId })
      .then(({ data }) => {
        // console.log(res.data)
        this.invoiceData = data.data

        console.log('data.data', data.data)

        if (this.actualForm.collection_method === 2) {
          this.reconstructProductListing(data.data.product_listing)
        } else {
          this.actualForm.quantity = 1
        }
        this.getPaymentRecordsByBill()

        const breadcrumbUpdatePayload = [
          { title: 'Collections' },
        ]
        this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
      })
  },
  methods: {
    getPaymentRecordsByBill() {
      this.searchTerm = ''
      const payload = {
        limit: 10,
        skip: 0,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        bill_invoice: this.invoiceData.invoice_no,
        account_id: this.invoiceData.account_id,
      }

      this.$store.dispatch('paymentRecords/getPaymentRecordsByBill', payload).then(() => {
        this.isBusy = false

        // alert(this.invoiceData.invoice_no)
      })
    },
    reconstructProductListing(obj) {
      console.log(obj)
      if (obj.length > 0) {
        this.reconstructProductListingResult = obj.length
        obj.forEach(item => {
          this.selectedProducts.push({
            collection_id: item.CustomerBillItem.collection_id,
            id: item.CustomerBillItem.id,
            product_id: item.Product.id,
            name: item.Product.title,
            quantity: item.CustomerBillItem.quantity,
            amount: item.CustomerBillItem.amount,
            total_amount: item.CustomerBillItem.total_amount,
          })
          console.log('asdasdas', this.selectedProducts)
        })
        return obj.length
      }
      this.reconstructProductListingResult = obj.length
      return obj.length
    },
    onSubmit() {
      const payload = {
        id: this.$route.params.id,
        ...this.actualForm,
        product_listing: this.selectedProducts,
        account_id: this.$store.getters['accounts/getAccountId'],
      }
      this.$store.dispatch('collections/createCollectionsBillMethod', payload)
        .then(res => {
          if (res.data.response_code === 2000) {
            this.$router.push({
              name: 'merchant-collections-form',
              params: { id: this.$route.params.id, mode: 'view' },
            })
          }
        })
    },
    addRow(e) {
      console.log(e)
      this.selectedProducts.push({
        ...e,
        quantity: 1,
        id: null,
        collection_id: this.$route.params.id,
        total_amount: 0,
      })
      this.selected = null
    },
    calculatedRowPricePayment(quantity, price) {
      this.actualForm.total_amount = quantity * price
      return this.actualForm.total_amount
    },
    calculatedRowPrice(index, quantity, price) {
      this.selectedProducts[index].total_amount = quantity * price
      return this.selectedProducts[index].total_amount
    },
    removeItem(index) {
      this.selectedProducts.splice(index, 1)
    },
    cancelForm() {
      this.$router.replace({ name: this.parentPageNamedRoute })
    },
    addNewProduct() {
      alert('WIP')
    },
  },
}
</script>
