<template>
  <div>
    <lp-datatable
      id="billPaymentRecords"
      ref="billPaymentRecords"
      :selectable-table="false"
      title="Payment Records"
      store-module="paymentRecords"
      store-data-endpoint="getPaymentRecordsByBill"
      store-getter-endpoint="getPaymentRecordsByBill"
      pagination-state-key="paymentRecordsByBill"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      :extra-parameter="{bill_invoice: billInvoice, account_id: accountId}"
      default-search-field="reference_number"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
    />
  </div>
</template>

<script>

import LpDatatable from '@/leanpay-components/LPDataTable.vue'
import Config from '@/views/administration/accounts/details/collections/component/companyCollectionPaymentRecordComponentConfig'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: { LpDatatable },
  mixins: [humaniseMixin],
  props: {
    billInvoice: {
      required: true,
    },
    accountId: {
      required: true,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Merchant API' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },
  methods: {
    doExtra(e) {
      console.log(e)
    },
  },
}
</script>
