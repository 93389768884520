const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Download Template',
      //   buttonRef: 'downloadTemplateBtn',
      //   variant: 'info',
      // },
    ],
    buttons: {
      createButton: false,
      createButtonLabel: 'Create New Collection',
      createButtonRoute: 'administration-collection-new',
      refresh: true,
    },
    tableActionField: false,
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: false,
  },
  formData() {
    return {
      title: {
        edit: 'Edit Account',
        view: 'View Account',
        remove: 'Remove Account',
        create: 'Create New Account',
      },
      form: [{
        formContext: 'Account',
        formName: 'account',
        elem: [
          {
            key: 'full_name',
            fieldName: 'Full Name',
            type: 'text',
            rules: 'required|alpha-spaces',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'email',
            fieldName: 'Email',
            type: 'email',
            rules: 'required|email:true',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'phone_number',
            fieldName: 'Phone Number',
            type: 'phone',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'password',
            fieldName: 'Password',
            type: 'password',
            rules: 'required',
            show: {
              edit: false,
              remove: true,
              view: false,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'confirmPassword',
            fieldName: 'Confirm Password',
            type: 'password',
            rules: 'confirmed:password',
            show: {
              edit: false,
              remove: true,
              view: false,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'is_active',
            fieldName: 'Active?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'payment_date', label: 'Payment Date', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'reference_number', label: 'Reference Number', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'payment_method', label: 'Payment Method', sortable: true, visible: true, type: 'underscore_string',
        },
        {
          key: 'payment_mode', label: 'Payment Mode', sortable: true, visible: true, type: 'underscore_string',
        },
        {
          key: 'transaction_fee',
          label: 'Transaction Fee',
          sortable: true,
          visible: true,
          class: 'text-right',
          type: 'monetary',
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
          visible: true,
          class: 'text-right',
          type: 'monetary',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, type: 'date',
        },

        {
          key: 'id', label: 'Id', sortable: true, visible: false,
        },

        {
          key: 'total',
          label: 'Total',
          sortable: true,
          visible: false,
          class: 'text-right',
          type: 'monetary',
        },

        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: false,
        },
      ],
    }
  },
}

export default Config
